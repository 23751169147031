
#root {
    height: 100%;
}

.mainAppContainer {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    width: 100%;
    height: 100%;
}
.tabView {
    height:100%;
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: 0;
}

.tabView div:last-child {
    margin-top: auto;
}

.spacer{
    width: 100px;
    height: 100px;
}


.disable-scrollbars::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
}
      
.disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

@media screen and (max-height: 900px) {
    .tabView {
        overflow: auto; 
        right: -17px;
        justify-content: left;
    } /* Or change the height or whatever */ 
}
@font-face {
    font-family: gilroy-bold;
    src: url(/static/media/Gilroy-Bold.3cf0ee27.ttf);
}



.tab {
    border-radius: 10px;
    width: 100px;
    height: 100px;
    text-align: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    border: 0px solid white;
    z-index: 3;

    transition: all 100ms ease;
    transition-property: border;
}

.active {
    border: 12px solid white;
    transition: all 100ms ease;
    transition-property: border;
}


.tab .tab-name {
    position: absolute;
    color: white;
    margin: 0 auto;
    width: 100%;
    font-size: 12px;
    font-family: gilroy-bold;

    bottom: 0px;
    opacity: 0.0;
    transition: all 500ms ease;
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    transition-property: bottom, opacity, -webkit-transform;
    transition-property: bottom, opacity, transform;
    transition-property: bottom, opacity, transform, -webkit-transform;

}

.tab:hover .tab-name{
    /* transform: rotateY(0deg) */
    bottom: 5px;
    opacity: 0.8;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    
}

.tab .tab-icon {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none;
}



.logotab {

    border-radius: 50%;
    width: 120px;
    height: 120px;
    text-align: center;
    position: relative;
    margin-top: 20px;
    
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    border: 0px solid white;
    z-index: 3;

    margin-bottom: auto;

}

.logotab:hover {

}

.logotab .logotab-icon {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none;
    width: 80px;
    height: 80px;
}





.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }


@media screen and (max-height: 800px) {
    .logotab {
        margin-bottom: auto;
    } /* Or change the height or whatever */ 
}

  



.mainWindow {
    background-color: white;
    border-radius: 50px 0px 0px 50px;
    position: relative;
    flex: 1 1;
    box-shadow: -10px 0px 30px 10px rgba(0,0,0,0.2);
    box-sizing: border-box;
    flex-wrap: 0;
}
:root {
    --lightmode-text-primary: #3A3D43;
}

@font-face {
    font-family: gilroy-black;
    src: url(/static/media/Gilroy-Black.d59719bc.ttf);
}

@font-face {
    font-family: gilroy-extrabold;
    src: url(/static/media/Gilroy-ExtraBold.07e6a6cd.ttf);
}

@font-face {
    font-family: gilroy-bold;
    src: url(/static/media/Gilroy-Bold.3cf0ee27.ttf);
}

@font-face {
    font-family: gilroy-regular;
    src: url(/static/media/Gilroy-Regular.ae5e7255.ttf);
}

@font-face {
    font-family: gilroy-medium;
    src: url(/static/media/Gilroy-Medium.6444f14a.ttf);
}

@font-face {
    font-family: gilroy-light;
    src: url(/static/media/Gilroy-Light.73bfa12b.ttf);
}









.contentWindow {
    display: flex;
    flex-direction: column;
    padding: 50px;
    height: 100%;
    box-sizing: border-box;
}

.windowHeader {
    color: #3A3D43;
    color: var(--lightmode-text-primary);
    font-family: gilroy-black;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 20px;

}

.fade-up-complete {
    opacity: 1.0;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);

    transition: all 400ms ease-out;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;

}

.fade-up-start {
    opacity: 0.0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);

    transition: all 400ms ease-out;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}




.windowSubheader {
    color: #3A3D43;
    color: var(--lightmode-text-primary);
    font-family: gilroy-medium;
}

.plaintext {
    font-family: gilroy-light;
    font-size: 16px;
}

.focusedText {

}


.resizableLR {
    display: flex;
    align-items: center;

    /* Misc */

    height: 90%;
    width: 100%;
    position: relative;
    background-color: #eeeeee;
    box-shadow: inset 5px 5px 20px #cccccc;
    border-radius: 40px;
}

.resizableLeft {

    width: 60%;
    height: 100%;

    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}

.resizableRight {
    /* Take the remaining width */
    flex: 1 1;
    height: 100%;

    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #B9B9BB;
    border-radius: 40px;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 20px;

    transition: width 100ms;
}

.resizer {
    z-index: 3;
    background-color: #B9B9BB;
    cursor: ew-resize;
    height: 60px;
    width: 30px;
    border-radius: 15px 15px 15px 15px;
    -webkit-transform: translateX(15px);
            transform: translateX(15px);
}
.featureCard {
    min-width: 200px;
    max-width: 300px;
    width: 30%;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #74747a
}

.featureCardHeader {
    display: flex;
    justify-content: left;
    align-items: center; 
    border-radius: 10px 10px 10px 10px;
    height: 48px;
}

.featureCardHeaderText {
    text-align: center;
    font-size: 20px;
    font-family: gilroy-bold;
    background: linear-gradient(#e66465 50%, #9198e5);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    margin-left: 20px;
}

.featureCardDescription {
    display: none;
}

.navbar {

    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 1px 1px 1px 1px #CCCCCC;
}

.navbarContent {
    margin: auto;
    max-width: 1400px;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbarTabs {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.signupButtons {
    height: 100%;


    display: flex;
    align-items: center;
    justify-content: center;
}

.getNotifications {
    cursor: pointer;
    background-color: #3B3D43;
    height: 40px;
    color: white;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px;
    font-family: gilroy-medium;
    border-radius: 4px;
}

.getInvolved {
    cursor: pointer;
    background: linear-gradient(299deg, rgba(175,59,88,1) 0%, rgba(139,161,227,1) 100%);;
    background-blend-mode: screen;
    height: 40px;
    color: white;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px;
    font-family: gilroy-bold;
    border-radius: 4px;
}

.navbarTab {
    height: 100%;
}

.navbarLogoWrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.navbarLogo {
    height: 100%;
}

.navbarLogoText {
    font-family: gilroy-medium;
    font-size: 30px;
    margin-left: 20px;
    letter-spacing: 2px;
    color: #3B3D43
}

.navbarLogoTextAI {
    font-family: gilroy-regular;
    background: linear-gradient(299deg, rgba(175,59,88,1) 0%, rgba(139,161,227,1) 100%);
    margin-left: 6px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}






.homepageContainer {
    width: 100%;
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
	12% {
		background-position: 25% 5%;
	}
	25% {
		background-position: 50% 10%;
	}
    37% {
		background-position: 75% 35%;
	}
    50% {
		background-position: 100% 25%;
	}
    62% {
		background-position: 75% 55%;
	}
    75% {
		background-position: 50% 80%;
	}
    87% {
		background-position: 25% 60%;
	}
    100% {
        background-position: 0% 0%;
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
	12% {
		background-position: 25% 5%;
	}
	25% {
		background-position: 50% 10%;
	}
    37% {
		background-position: 75% 35%;
	}
    50% {
		background-position: 100% 25%;
	}
    62% {
		background-position: 75% 55%;
	}
    75% {
		background-position: 50% 80%;
	}
    87% {
		background-position: 25% 60%;
	}
    100% {
        background-position: 0% 0%;
    }
}

.titleCard {
    width: 100%;
    height: 75%;
    position: absolute;
    min-height: 650px;
    max-height: 1500px;
    
    background: linear-gradient(-45deg, #f2a74e40, #f3474f40, #23a6d540, #23d5ab40);
    /* opacity: 0.4; */

    /* linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
    
    
    /* background-blend-mode: screen; */
    /* background-position: 0% 100%; */
    background-size: 200% 200%;
    -webkit-animation: gradient 15s ease infinite;
            animation: gradient 15s ease infinite;
    

}

.titleCardContent {
    margin: auto;
    max-width: 1400px;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.titleCardLeft {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.titleCardMessage {
    font-size: 72px;
    font-family: gilroy-extrabold;
    color: #3B3D43;
}

.titleCardMessageAccent {
    font-family: gilroy-black;
    color: white;
    font-size: 60px;
}

.titleCardRight {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.titleCardMainGraphic {
    width: 100%;
}

.titleCardSubmessage {
    font-family: gilroy-light;
    color: #60646d;
}




html {
    width: 100%;
    height: 100%;
}

body {
  margin: 0;
  background-color: #e2e2e2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

