
.navbar {

    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 1px 1px 1px 1px #CCCCCC;
}

.navbarContent {
    margin: auto;
    max-width: 1400px;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbarTabs {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.signupButtons {
    height: 100%;


    display: flex;
    align-items: center;
    justify-content: center;
}

.getNotifications {
    cursor: pointer;
    background-color: #3B3D43;
    height: 40px;
    color: white;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px;
    font-family: gilroy-medium;
    border-radius: 4px;
}

.getInvolved {
    cursor: pointer;
    background: linear-gradient(299deg, rgba(175,59,88,1) 0%, rgba(139,161,227,1) 100%);;
    background-blend-mode: screen;
    height: 40px;
    color: white;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px;
    font-family: gilroy-bold;
    border-radius: 4px;
}

.navbarTab {
    height: 100%;
}

.navbarLogoWrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.navbarLogo {
    height: 100%;
}

.navbarLogoText {
    font-family: gilroy-medium;
    font-size: 30px;
    margin-left: 20px;
    letter-spacing: 2px;
    color: #3B3D43
}

.navbarLogoTextAI {
    font-family: gilroy-regular;
    background: linear-gradient(299deg, rgba(175,59,88,1) 0%, rgba(139,161,227,1) 100%);
    margin-left: 6px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}



