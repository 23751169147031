

.homepageContainer {
    width: 100%;
}

@keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
	12% {
		background-position: 25% 5%;
	}
	25% {
		background-position: 50% 10%;
	}
    37% {
		background-position: 75% 35%;
	}
    50% {
		background-position: 100% 25%;
	}
    62% {
		background-position: 75% 55%;
	}
    75% {
		background-position: 50% 80%;
	}
    87% {
		background-position: 25% 60%;
	}
    100% {
        background-position: 0% 0%;
    }
}

.titleCard {
    width: 100%;
    height: 75%;
    position: absolute;
    min-height: 650px;
    max-height: 1500px;
    
    background: linear-gradient(-45deg, #f2a74e40, #f3474f40, #23a6d540, #23d5ab40);
    /* opacity: 0.4; */

    /* linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
    
    
    /* background-blend-mode: screen; */
    /* background-position: 0% 100%; */
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;
    

}

.titleCardContent {
    margin: auto;
    max-width: 1400px;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.titleCardLeft {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.titleCardMessage {
    font-size: 72px;
    font-family: gilroy-extrabold;
    color: #3B3D43;
}

.titleCardMessageAccent {
    font-family: gilroy-black;
    color: white;
    font-size: 60px;
}

.titleCardRight {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.titleCardMainGraphic {
    width: 100%;
}

.titleCardSubmessage {
    font-family: gilroy-light;
    color: #60646d;
}



