.featureCard {
    min-width: 200px;
    max-width: 300px;
    width: 30%;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #74747a
}

.featureCardHeader {
    display: flex;
    justify-content: left;
    align-items: center; 
    border-radius: 10px 10px 10px 10px;
    height: 48px;
}

.featureCardHeaderText {
    text-align: center;
    font-size: 20px;
    font-family: gilroy-bold;
    background: linear-gradient(#e66465 50%, #9198e5);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    margin-left: 20px;
}

.featureCardDescription {
    display: none;
}