
#root {
    height: 100%;
}

.mainAppContainer {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    width: 100%;
    height: 100%;
}