

.resizableLR {
    display: flex;
    align-items: center;

    /* Misc */

    height: 90%;
    width: 100%;
    position: relative;
    background-color: #eeeeee;
    box-shadow: inset 5px 5px 20px #cccccc;
    border-radius: 40px;
}

.resizableLeft {

    width: 60%;
    height: 100%;

    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}

.resizableRight {
    /* Take the remaining width */
    flex: 1;
    height: 100%;

    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #B9B9BB;
    border-radius: 40px;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 20px;

    transition: width 100ms;
}

.resizer {
    z-index: 3;
    background-color: #B9B9BB;
    cursor: ew-resize;
    height: 60px;
    width: 30px;
    border-radius: 15px 15px 15px 15px;
    transform: translateX(15px);
}