@font-face {
    font-family: gilroy-bold;
    src: url('../../../../static/fonts/gilroy/Gilroy-Bold.ttf');
}



.tab {
    border-radius: 10px;
    width: 100px;
    height: 100px;
    text-align: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    border: 0px solid white;
    z-index: 3;

    transition: all 100ms ease;
    transition-property: border;
}

.active {
    border: 12px solid white;
    transition: all 100ms ease;
    transition-property: border;
}


.tab .tab-name {
    position: absolute;
    color: white;
    margin: 0 auto;
    width: 100%;
    font-size: 12px;
    font-family: gilroy-bold;

    bottom: 0px;
    opacity: 0.0;
    transition: all 500ms ease;
    transform: rotateX(-90deg);
    transition-property: bottom, opacity, transform;

}

.tab:hover .tab-name{
    /* transform: rotateY(0deg) */
    bottom: 5px;
    opacity: 0.8;
    transform: rotateX(0deg);
    
}

.tab .tab-icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}



.logotab {

    border-radius: 50%;
    width: 120px;
    height: 120px;
    text-align: center;
    position: relative;
    margin-top: 20px;
    
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    border: 0px solid white;
    z-index: 3;

    margin-bottom: auto;

}

.logotab:hover {

}

.logotab .logotab-icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 80px;
    height: 80px;
}





.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }


@media screen and (max-height: 800px) {
    .logotab {
        margin-bottom: auto;
    } /* Or change the height or whatever */ 
}

  
