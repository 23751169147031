:root {
    --lightmode-text-primary: #3A3D43;
}

@font-face {
    font-family: gilroy-black;
    src: url('../static/fonts/gilroy/Gilroy-Black.ttf');
}

@font-face {
    font-family: gilroy-extrabold;
    src: url('../static/fonts/gilroy/Gilroy-ExtraBold.ttf');
}

@font-face {
    font-family: gilroy-bold;
    src: url('../static/fonts/gilroy/Gilroy-Bold.ttf');
}

@font-face {
    font-family: gilroy-regular;
    src: url('../static/fonts/gilroy/Gilroy-Regular.ttf');
}

@font-face {
    font-family: gilroy-medium;
    src: url('../static/fonts/gilroy/Gilroy-Medium.ttf');
}

@font-face {
    font-family: gilroy-light;
    src: url('../static/fonts/gilroy/Gilroy-Light.ttf');
}









.contentWindow {
    display: flex;
    flex-direction: column;
    padding: 50px;
    height: 100%;
    box-sizing: border-box;
}

.windowHeader {
    color: var(--lightmode-text-primary);
    font-family: gilroy-black;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 20px;

}

.fade-up-complete {
    opacity: 1.0;
    transform: translateY(0px);

    transition: all 400ms ease-out;
    transition-property: opacity, transform;

}

.fade-up-start {
    opacity: 0.0;
    transform: translateY(15px);

    transition: all 400ms ease-out;
    transition-property: opacity, transform;
}




.windowSubheader {
    color: var(--lightmode-text-primary);
    font-family: gilroy-medium;
}

.plaintext {
    font-family: gilroy-light;
    font-size: 16px;
}

.focusedText {

}