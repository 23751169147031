.tabView {
    height:100%;
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: 0;
}

.tabView div:last-child {
    margin-top: auto;
}

.spacer{
    width: 100px;
    height: 100px;
}


.disable-scrollbars::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
}
      
.disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

@media screen and (max-height: 900px) {
    .tabView {
        overflow: auto; 
        right: -17px;
        justify-content: left;
    } /* Or change the height or whatever */ 
}