

.mainWindow {
    background-color: white;
    border-radius: 50px 0px 0px 50px;
    position: relative;
    flex: 1;
    box-shadow: -10px 0px 30px 10px rgba(0,0,0,0.2);
    box-sizing: border-box;
    flex-wrap: 0;
}